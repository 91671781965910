import { Badge, Button, FormLayout, Grid, MediaCard, Page, Popover, Select, InlineStack, Modal, LegacyStack, Text, Tag, Listbox, EmptySearchResult, AutoSelection, Combobox } from "@shopify/polaris";
import { QueryConstraint, collection, deleteDoc, doc, getDocs, limit, onSnapshot, orderBy, query, serverTimestamp, updateDoc, writeBatch } from "firebase/firestore";
import { useCallback, useEffect, useMemo, useState } from "react";
import { firestore } from "../firebase";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useArray } from "../utils/useArray";
import { MediaPoster } from "../components/media";
import { ProfileName } from "../components/profileName";
import { AutomationIcon, DeleteIcon, PlayIcon } from "@shopify/polaris-icons";
import { useNavigate } from "react-router-dom";

const ERRORS = new Array(12).fill(undefined).map((p, i) => i.toString())
const ERROR_LABELS = `Unknown Error
Body Not Found
Body is not valid, (body error: images are missing)
Payload not valid, (body is valid, but values are not, image file corrupt, ....)
Image scanning for objects was not successful
Image scanning for Card was not successful
Image scanning for Foot was not successful
Image scanning for Wall was not successful
Element looks too small on Image
Image scanning for Heel detection was not successful
Credit card dimensions are not close to 8.56cm x 5.398cm
Foot is not fully rested`.split('\n')

function MultiselectTagCombobox({ values, setValues, tags }: { values: string[], setValues: (values: string[]) => void, tags: string[] }) {
  const [value, setValue] = useState('');
  const [suggestion, setSuggestion] = useState('');

  const handleActiveOptionChange = useCallback(
    (activeOption: string) => {
      const activeOptionIsAction = activeOption === value;

      if (!activeOptionIsAction && !values.includes(activeOption)) {
        setSuggestion(activeOption);
      } else {
        setSuggestion('');
      }
    },
    [value, values],
  );
  const updateSelection = useCallback(
    (selected: string) => {
      const nextSelectedTags = new Set([...values]);

      if (nextSelectedTags.has(selected)) {
        nextSelectedTags.delete(selected);
      } else {
        nextSelectedTags.add(selected);
      }
      setValues([...nextSelectedTags]);
      setValue('');
      setSuggestion('');
    },
    [values],
  );

  const removeTag = useCallback(
    (tag: string) => () => {
      updateSelection(tag);
    },
    [updateSelection],
  );

  const getAllTags = useCallback(() => {
    const savedTags: string[] = tags;
    return [...new Set([...savedTags, ...values].sort())];
  }, [values]);

  const formatOptionText = useCallback(
    (option: string) => {
      const trimValue = value.trim().toLocaleLowerCase();
      const matchIndex = option.toLocaleLowerCase().indexOf(trimValue);

      if (!value || matchIndex === -1) return option;

      const start = option.slice(0, matchIndex);
      const highlight = option.slice(matchIndex, matchIndex + trimValue.length);
      const end = option.slice(matchIndex + trimValue.length, option.length);

      return (
        <p>
          {start}
          <Text fontWeight="bold" as="span">
            {highlight}
          </Text>
          {end}
        </p>
      );
    },
    [value],
  );

  const escapeSpecialRegExCharacters = useCallback(
    (value: string) => value.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'),
    [],
  );

  const options = useMemo(() => {
    let list;
    const allTags = getAllTags();
    const filterRegex = new RegExp(escapeSpecialRegExCharacters(value), 'i');

    if (value) {
      list = allTags.filter((tag) => tag.match(filterRegex));
    } else {
      list = allTags;
    }

    return [...list];
  }, [value, getAllTags, escapeSpecialRegExCharacters]);

  const verticalContentMarkup =
    values.length > 0 ? (
      <LegacyStack spacing="extraTight" alignment="center">
        {values.map((tag) => (
          <Tag key={`option-${tag}`} onRemove={removeTag(tag)}>
            {tag}
          </Tag>
        ))}
      </LegacyStack>
    ) : null;

  const optionMarkup =
    options.length > 0
      ? options.map((option) => {
        return (
          <Listbox.Option
            key={option}
            value={option}
            selected={values.includes(option)}
            accessibilityLabel={option}
          >
            <Listbox.TextOption selected={values.includes(option)}>
              {formatOptionText(option)}
            </Listbox.TextOption>
          </Listbox.Option>
        );
      })
      : null;

  const noResults = value && !getAllTags().includes(value);

  const actionMarkup = noResults ? (
    <Listbox.Action value={value}>{`Add "${value}"`}</Listbox.Action>
  ) : null;

  const emptyStateMarkup = optionMarkup ? null : (
    <EmptySearchResult
      title=""
      description={`No tags found matching "${value}"`}
    />
  );

  const listboxMarkup =
    optionMarkup || actionMarkup || emptyStateMarkup ? (
      <Listbox
        autoSelection={AutoSelection.None}
        onSelect={updateSelection}
        onActiveOptionChange={handleActiveOptionChange}
      >
        {actionMarkup}
        {optionMarkup}
      </Listbox>
    ) : null;

  return (
    <Combobox
      allowMultiple
      activator={
        <Combobox.TextField
          autoComplete="off"
          label="Search tags"
          labelHidden
          value={value}
          suggestion={suggestion}
          placeholder="Search tags"
          verticalContent={verticalContentMarkup}
          onChange={setValue}
        />
      }
    >
      {listboxMarkup}
    </Combobox>
  );
}

function Description({ recording, tags }: { recording: any, tags: string[] }) {
  const [disabled, setDisabled] = useState<boolean>(false)
  const setError = useCallback((error: string) => {
    setDisabled(true)
    updateDoc(doc(firestore, 'recordings', recording.id), { error }).then(() => setDisabled(false))
  }, [])
  const setTags = useCallback((tags: string[]) => {
    setDisabled(true)
    updateDoc(doc(firestore, 'recordings', recording.id), { tags }).then(() => setDisabled(false))
  }, [])
  return <InlineStack gap="100">
    {' #' + recording.id}
    {recording.testList && <Badge tone="info">Test list</Badge>}
    <Badge>{recording.model}</Badge>
    <Badge>{recording.browser}</Badge>
    <Select disabled={disabled} label value={recording.error} onChange={setError} options={[{ label: 'Success', value: '' }, ...ERRORS.map((error, i) => ({ label: [i, ERROR_LABELS[i]].join(' - '), value: error }))]} />
    <MultiselectTagCombobox values={recording.tags || []} setValues={setTags} tags={tags} />
  </InlineStack>
}

export function Recordings() {
  const recordings = useArray<any>([])
  const tags = useMemo(() => recordings.value.reduce((p, c) => p.concat(c.tags), []).filter(Boolean), [recordings])
  console.log('tags', tags)
  useEffect(() => {
    const queryConstraints: QueryConstraint[] = [orderBy("timestamp", "desc"), limit(100)];
    // if (filter) {
    //   if (filter === 'unread')
    //     queryConstraints.unshift(
    //       where("answered", "==", false)
    //     );
    // }
    const unsubscribe = onSnapshot(
      query(
        collection(firestore, "recordings"),
        ...queryConstraints
      ),
      (snapshot) =>
        snapshot
          .docChanges()
          .reverse()
          .forEach((change) => {
            const recording = {
              id: change.doc.id,
              ...change.doc.data(),
            } as any;
            recordings.removeById(recording.id);
            if (change.type !== "removed") recordings.unshift(recording);
          })
    );
    return () => {
      recordings.clear();
      return unsubscribe();
    };
  }, []);
  const { isLoading: profileLoading, data: profiles } = useQuery({
    queryKey: ['profiles'],
    queryFn: () => getDocs(collection(firestore, 'profiles')).then(
      s => [{ value: '', label: 'Select profile' }].concat(s.docs.map(d => ({ value: d.id, label: d.data().name })))
    ),
    refetchOnWindowFocus: false,
  });
  const [profile, setProfile] = useState<string | undefined>()
  const qrCode = useMemo(() => {
    const url = new URL('https://qr.realift.io/')
    const app = new URL('https://realfoot.getrealift.com/record')
    app.searchParams.set('id', profile!)
    url.searchParams.set('chl', app.toString())
    return url.toString()
  }, [profile])
  const [popoverActive, setPopoverActive] = useState(!true);
  const togglePopoverActive = useCallback(
    () => setPopoverActive((popoverActive) => !popoverActive),
    [],
  );
  const activator = (
    <Button onClick={togglePopoverActive} loading={profileLoading}>
      Add new Recording
    </Button>
  );

  const toggleTestList = useCallback((id: string, testList: boolean) => updateDoc(doc(firestore, 'recordings', id), { testList }), [])

  const deleteOne = useCallback((id: string) => window.confirm('Are you sure you want to delete this recording?') && deleteDoc(doc(firestore, 'recordings', id)), [])

  const setRecordingProfile = useCallback((id: string, profile: string) => updateDoc(doc(firestore, 'recordings', id), { profile }), [])

  const navigate = useNavigate()
  const runAllMutation = useMutation<void, Error, { recordings: any[], environment: string }>({
    mutationFn: ({ recordings, environment }) => {
      console.log(JSON.stringify(recordings, null, 2))
      const batch = writeBatch(firestore)
      recordings.forEach(
        recording => {
          batch.set(
            doc(collection(firestore, 'runs')),
            {
              environment,
              profile: recording.profile,
              recording: recording.id,
              status: 0,
              timestamp: serverTimestamp()
            }
          )
        }
      )
      return batch.commit()
    },
    onSuccess: () => navigate('/runs')
  })
  const [runAllEnvironment, setRunAllEnvironment] = useState('main');
  const runAll = useCallback(() => runAllMutation.mutate({ recordings: recordings.value, environment: runAllEnvironment }), [recordings, runAllEnvironment])

  const [runAllActive, setRunAllActive] = useState(false);

  return <Page
    title="Recordings"
    primaryAction={<Popover
      active={popoverActive}
      activator={activator}
      onClose={togglePopoverActive}
      ariaHaspopup={false}
      sectioned
    >
      <FormLayout>
        <Select label="Select profile" options={profiles} value={profile} onChange={setProfile} />
        {profile && <img
          src={qrCode}
          style={{ width: 250, display: 'block', aspectRatio: '1/1' }}
        />}
      </FormLayout>
    </Popover>}
    secondaryActions={[
      {
        content: 'Run all',
        onAction: () => setRunAllActive(true),
        loading: runAllMutation.isPending,
      }
    ]}
  >
    <Modal
      instant
      size="small"
      open={runAllActive}
      onClose={() => setRunAllActive(false)}
      title="Please select environment"
      primaryAction={{
        content: 'Run',
        onAction: runAll,
      }}
      secondaryActions={[
        {
          content: 'Cancel',
          onAction: () => setRunAllActive(false),
        },
      ]}
    >
      <Modal.Section>
        <LegacyStack vertical>
          <LegacyStack.Item>
            <Select
              label="Environment"
              options={[
                { label: 'Main', value: 'main' },
                { label: 'Staging', value: 'staging' },
              ]}
              value={runAllEnvironment}
              onChange={setRunAllEnvironment}
            />
          </LegacyStack.Item>
        </LegacyStack>
      </Modal.Section>
    </Modal>
    <Grid>
      {
        recordings.value.map(
          recording => <Grid.Cell key={recording.id} columnSpan={{ xs: 3, sm: 2, md: 3, lg: 3, xl: 3 }}>
            <MediaCard
              title={recording.profile ? <ProfileName id={recording.profile} /> : <Select label options={profiles} onChange={(selected) => setRecordingProfile(recording.id, selected)} />}
              // primaryAction={{
              //   content: 'Run now',
              //   onAction: () => { },
              // }}
              description={<Description recording={recording} tags={tags} /> as any}
              popoverActions={[
                { content: 'Run now', icon: PlayIcon, url: '/runs/add?profile=' + recording.profile + '&recording=' + recording.id },
                { content: (recording.testList ? 'Remove' : 'Add') + ' to Test List', icon: AutomationIcon, /** , suffix: <Icon source={recording.testList ? CheckIcon : XIcon} /> */ onAction: () => toggleTestList(recording.id, !recording.testList) },
                { content: 'Delete', icon: DeleteIcon, destructive: true, onAction: () => deleteOne(recording.id) }
              ]}
              portrait
              size="small"
            >
              <MediaPoster source={recording.recording} />
            </MediaCard>
          </Grid.Cell>
        )
      }
    </Grid>
  </Page>
}