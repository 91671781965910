import { Card, Form, FormLayout, Layout, Loading, Page, Select, SelectOption, SkeletonBodyText, SkeletonDisplayText, SkeletonPage, TextContainer, TextField } from "@shopify/polaris";
import { useNavigate, useParams } from "react-router-dom";
import range from 'lodash.range';
import { useForm, useField } from '@shopify/react-form';
import { addDoc, collection, doc, getDoc, updateDoc } from "firebase/firestore";
import { firestore } from "../../firebase";
import { useQuery, useQueryClient } from '@tanstack/react-query';

export function ProfilesEdit() {
  const { id } = useParams()
  // const [profile, setProfile] = useState<any>()
  const navigate = useNavigate()
  const client = useQueryClient()
  const reference = doc(firestore, "profiles", id!)
  const { isLoading, error, data: profile } = useQuery({
    queryKey: ["profiles", id],
    queryFn: () => getDoc(
      reference,
    ).then(
      r => r.data()
    ),
    enabled: id !== 'add',

  })
  const form = useForm({
    fields: {
      name: useField(profile?.name),
      gender: useField(profile?.gender),
      feet: {
        length: useField(profile?.feet?.length),
        width: useField(profile?.feet?.width),
        // height: useField(profile?.feet?.height),
      },
      averageShoeSize: {
        us: useField(profile?.averageShoeSize?.us),
        eu: useField(profile?.averageShoeSize?.eu),
        uk: useField(profile?.averageShoeSize?.uk),
        au: useField(profile?.averageShoeSize?.au),
      }
    },
    onSubmit: (values) => {
      if (id === 'add') {
        return addDoc(
          collection(firestore, "profiles"),
          values,
        ).then(
          ref => {
            navigate('/profiles/' + ref.id)
            return { status: 'success' }
          }
        )
      } else {
        return updateDoc(
          reference,
          values
        ).then(
          () => {
            client.invalidateQueries({ queryKey: ["profiles", id] })
            return { status: 'success' }
          }
        )
      }
    },
  })

  if (isLoading) return <SkeletonPage primaryAction>
    <Layout>
      {Array.from({ length: 3 }).map((_, i) => <Layout.AnnotatedSection title={<SkeletonDisplayText />}>
        <Card>
          <TextContainer>
            <SkeletonDisplayText size="small" />
            <SkeletonBodyText />
          </TextContainer>
        </Card>
      </Layout.AnnotatedSection>)
      }
    </Layout>
  </SkeletonPage>

  const title = id != 'add' ? 'Edit profile of ' + profile?.name : 'Add profile'
  return <Page title={title} backAction={{ content: 'Profiles', url: '../profiles' }} primaryAction={{ content: 'Save', onAction: form.submit, loading: form.submitting }}>
    {(isLoading || form.submitting) && <Loading />}
    <Form onSubmit={form.submit}>
      <Layout>
        <Layout.AnnotatedSection
          title="Profile Details"
        // description="Jaded Pixel will use this as your account information."
        >
          <Card>
            <FormLayout>
              <TextField
                label="Name"
                autoComplete="name"
                value={form.fields.name.value}
                onChange={form.fields.name.onChange}
              />
              <Select
                label="Gender"
                options={[
                  {
                    value: "",
                    label: "Select Gender",
                  },
                  {
                    value: "male",
                    label: "Male",
                  },
                  {
                    value: "female",
                    label: "Female",
                  },
                  {
                    value: "children",
                    label: "Children",
                  },
                  {
                    value: "youth",
                    label: "Youth",
                  },
                ]}
                value={form.fields.gender.value}
                onChange={form.fields.gender.onChange}
              />
            </FormLayout>
          </Card>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection title="Feet measurements">
          <Card>
            <FormLayout>
              <FormLayout.Group condensed>
                <TextField label="Length" type="number" autoComplete="off" suffix="cm" value={form.fields.feet.length.value} onChange={form.fields.feet.length.onChange} />
                <TextField label="Width" autoComplete="off" suffix="cm" value={form.fields.feet.width.value} onChange={form.fields.feet.width.onChange} />
              </FormLayout.Group>
            </FormLayout>
          </Card>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection
          title="Average shoe size"
        // description="Jaded Pixel will use this as your account information."
        >
          <Card>
            <FormLayout>
              <FormLayout.Group condensed>
                <Select
                  label="US Size"
                  options={[
                    {
                      value: "",
                      label: "Select Size",
                    },
                    ...range(1, 16.1, 0.5).map(
                      value => value.toString() as SelectOption
                    )
                  ]}
                  value={form.fields.averageShoeSize.us.value}
                  onChange={form.fields.averageShoeSize.us.onChange}
                />
                <Select
                  label="EU Size"
                  options={[
                    {
                      value: "",
                      label: "Select Size",
                    },
                    ...range(16, 50.51, 0.5).map(
                      value => value.toString() as SelectOption
                    )
                  ]}
                  value={form.fields.averageShoeSize.eu.value}
                  onChange={form.fields.averageShoeSize.eu.onChange}
                />
                <Select
                  label="UK Size"
                  options={[
                    {
                      value: "",
                      label: "Select Size",
                    },
                    ...range(1, 13.1, 0.5).map(
                      value => value.toString() as SelectOption
                    )
                  ]}
                  value={form.fields.averageShoeSize.uk.value}
                  onChange={form.fields.averageShoeSize.uk.onChange}
                />
                <Select
                  label="AU Size"
                  options={[
                    {
                      value: "",
                      label: "Select Size",
                    },
                    ...range(1, 13.1, 0.5).map(
                      value => value.toString() as SelectOption
                    )
                  ]}
                  value={form.fields.averageShoeSize.au.value}
                  onChange={form.fields.averageShoeSize.au.onChange}
                />
              </FormLayout.Group>
            </FormLayout>
          </Card>
        </Layout.AnnotatedSection>
      </Layout>
    </Form>
  </Page>
}

function setRun(arg0: { id: string; }) {
  throw new Error("Function not implemented.");
}
