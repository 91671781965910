import { useArray } from "../../utils/useArray";
import { ReactElement, useEffect } from "react";
import { QueryConstraint, collection, limit, onSnapshot, orderBy, query } from "firebase/firestore";
import { firestore } from "../../firebase";
import { Badge, Card, IndexTable, Layout, Link, Page, PageActions, Text } from "@shopify/polaris";
import { Duration } from "../../components/duration";
import { useQueryClient } from "@tanstack/react-query";
import { ProfileName } from "../../components/profileName";
import { Diff } from "../../components/diff";
import { UnitValue } from "../../components/unitValue";
// import { LineChart, Line, ResponsiveContainer, CartesianGrid, XAxis, YAxis, Tooltip, Legend } from 'recharts'

const runStatus: Record<number, string> = {
  0: "Ready",
  1: "Queued",
  2: "Starting",
  3: "Running",
  4: "Success",
  5: "Erred",
}

export const STATUS_BADGES: Record<number, ReactElement> = {
  0: <Badge >{runStatus[0]}</Badge>,
  1: <Badge tone="attention">{runStatus[1]}</Badge>,
  2: <Badge progress="partiallyComplete" tone="attention">{runStatus[2]}</Badge>,
  3: <Badge progress="partiallyComplete" tone="warning">{runStatus[3]}</Badge>,
  4: <Badge progress="complete" tone="success">{runStatus[4]}</Badge>,
  5: <Badge progress="complete" tone="critical">{runStatus[5]}</Badge>,
}

export function RunsList() {
  const runs = useArray<any>([])
  const client = useQueryClient()
  useEffect(() => {
    const queryConstraints: QueryConstraint[] = [orderBy("timestamp", "desc"), limit(25)];
    // if (filter) {
    //   if (filter === 'unread')
    //     queryConstraints.unshift(
    //       where("answered", "==", false)
    //     );
    // }
    const unsubscribe = onSnapshot(
      query(
        collection(firestore, "runs"),
        ...queryConstraints
      ),
      (snapshot) =>
        snapshot
          .docChanges()
          .reverse()
          .forEach((change) => {
            const run = {
              id: change.doc.id,
              ...change.doc.data(),
            } as any;
            runs.removeById(run.id);
            if (change.type !== "removed") runs.unshift(run);
            if (change.type !== "removed") {
              client.setQueryData(["runs", run.id], run);
            } else {
              client.invalidateQueries({
                queryKey: ["runs", run.id],
              });
            }
          })
    );
    return () => {
      runs.clear();
      return unsubscribe();
    };
  }, []);
  // const data = [
  //   {
  //     name: 'Page A',
  //     uv: 4000,
  //     pv: 2400,
  //     amt: 2400,
  //   },
  //   {
  //     name: 'Page B',
  //     uv: 3000,
  //     pv: 1398,
  //     amt: 2210,
  //   },
  //   {
  //     name: 'Page C',
  //     uv: 2000,
  //     pv: 9800,
  //     amt: 2290,
  //   },
  //   {
  //     name: 'Page D',
  //     uv: 2780,
  //     pv: 3908,
  //     amt: 2000,
  //   },
  //   {
  //     name: 'Page E',
  //     uv: 1890,
  //     pv: 4800,
  //     amt: 2181,
  //   },
  //   {
  //     name: 'Page F',
  //     uv: 2390,
  //     pv: 3800,
  //     amt: 2500,
  //   },
  //   {
  //     name: 'Page G',
  //     uv: 3490,
  //     pv: 4300,
  //     amt: 2100,
  //   },
  // ]
  return <Page title="Runs" primaryAction={<PageActions
    primaryAction={{
      content: "Add Run",
      url: "/runs/add",
    }}
  />} fullWidth>
    <Layout>
      {/* <Layout.Section>
        <Card>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart
              // width={500}
              // height={300}
              data={data}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="pv" stroke="#8884d8" activeDot={{ r: 8 }} />
              <Line type="monotone" dataKey="uv" stroke="#82ca9d" />
            </LineChart>
          </ResponsiveContainer>
        </Card>
      </Layout.Section> */}
      <Layout.Section>
        <Card padding="0">
          <IndexTable
            resourceName={{ singular: 'run', plural: 'runs' }}
            itemCount={runs.value.length}
            headings={[
              { title: 'ID' },
              { title: 'Date' },
              { title: 'Profile' },
              { title: 'Measurements' },
              { title: 'Diff' },
              { title: 'Status' },
              { title: 'Duration' },
            ]}
            selectable={false}
            loading={runs.value.length === 0}
          >
            {
              runs.value.map((run, index) => (
                <IndexTable.Row id={run.id} key={run.id} position={index}>
                  <IndexTable.Cell>
                    <Link
                      dataPrimaryLink
                      url={"/run/" + run.id}
                    />
                    <Text variant="bodyMd" fontWeight="bold" as="span">
                      #{run.id} <Badge>{run.environment}</Badge> {run.tags?.map((tag: string) => <Badge key={tag}>{tag}</Badge>)}
                    </Text>
                  </IndexTable.Cell>
                  <IndexTable.Cell>
                    {new Date(run.startTimestamp?.seconds * 1_000 || Date.now()).toLocaleString()}
                  </IndexTable.Cell>
                  <IndexTable.Cell><ProfileName id={run.profile} /> (#{run.recording})</IndexTable.Cell>
                  {run.error && <IndexTable.Cell colSpan={2}><Text as="span" tone="critical">{run.error}</Text></IndexTable.Cell>}
                  {!run.error && (
                    <>
                      <IndexTable.Cell><UnitValue value={run.measurements?.foot_length} noSufix />/<UnitValue value={run.measurements?.foot_width} /></IndexTable.Cell>
                      <IndexTable.Cell><Diff id={run.profile} measurements={run.measurements} /></IndexTable.Cell>
                    </>
                  )}
                  <IndexTable.Cell>{STATUS_BADGES[run.status]}</IndexTable.Cell>
                  <IndexTable.Cell><Badge tone="info">
                    {<Duration start={run.startTimestamp?.seconds} end={run.endTimestamp?.seconds} /> as unknown as string}
                  </Badge></IndexTable.Cell>
                </IndexTable.Row>
              ))
            }
          </IndexTable>
        </Card>
      </Layout.Section>
    </Layout>
  </Page>
}