import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { useArray } from "../../utils/useArray";
import { QueryConstraint, collection, doc, getDoc, onSnapshot, orderBy, query } from "firebase/firestore";
import { firestore } from "../../firebase";
import { BlockStack, Card, DataTable, InlineError, Layout, LegacyCard, Page, SkeletonBodyText, SkeletonDisplayText, SkeletonPage, Spinner, TextContainer } from "@shopify/polaris";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { ProtocolMapping } from "@cloudflare/puppeteer";
import { STATUS_BADGES } from "./list";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Video } from "../../components/media";
import { UnitValue } from "../../components/unitValue";

type Events = keyof ProtocolMapping.Events
type Event<T extends Events = Events> = { id: number, method: T, params: ProtocolMapping.Events[T][0], sessionId?: string }
type DevtoolsCommand<T extends keyof ProtocolMapping.Commands = keyof ProtocolMapping.Commands> = {
  method: T
  params: ProtocolMapping.Commands[T]['paramsType'][0]
}

let id = 1

function Cast({ loadStatus, pageId }: { loadStatus: () => void, pageId: string }) {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const context = useMemo(() => canvasRef.current?.getContext("2d")!, [canvasRef.current]);
  const onMessage = useCallback((message: MessageEvent) => {
    const data: any = JSON.parse(message.data);
    switch (data.method) {
      case "Page.screencastFrame":
        const { data: image, sessionId, metadata } = (data as Event<'Page.screencastFrame'>).params
        sendMessage(JSON.stringify({
          id: id++,
          method: "Page.screencastFrameAck",
          params: { sessionId },
        } as DevtoolsCommand))
        setWidth(metadata.deviceWidth)
        setHeight(metadata.deviceHeight)
        fetch(`data:image/png;base64,${image}`)
          .then((res) => res.blob())
          .then((blob) => createImageBitmap(blob))
          .then((image) => {
            context.clearRect(0, 0, canvasRef.current!.width, canvasRef.current!.height);
            context.drawImage(image, 0, 0, metadata.deviceWidth, metadata.deviceHeight);
          });
        break;
    }
  }, [context])
  const ws = new URL(window.location.origin);
  ws.protocol = ws.protocol.replace('http', 'ws');
  ws.pathname = '/browser/cast/' + pageId;
  const { sendMessage, readyState } = useWebSocket(
    ws.toString(),
    {
      shouldReconnect: (closeEvent) => true,
      reconnectInterval: (attemptNumber) =>
        Math.min(Math.pow(2, attemptNumber + 1) * 3000, 10000),
      onOpen: () => {
        sendMessage(JSON.stringify({
          id: id++,
          method: "Page.startScreencast",
          params: {
            format: "png",
            // width: canvasRef.current!.width,
            // height: canvasRef.current!.height,
            // quality: 100,
          },
        } as DevtoolsCommand))
        loadStatus()
      },
      onMessage
    }
  );

  return (
    <div>
      <canvas ref={canvasRef} width={width} height={height} style={{ width: '100%', minHeight: 250, display: 'block' }} />
      <div style={{
        position: 'absolute',
        width: '100%',
        height: [ReadyState.CONNECTING, ReadyState.CLOSING].includes(readyState) ? '100%' : undefined,
        top: 0,
        display: [ReadyState.CONNECTING, ReadyState.CLOSING].includes(readyState) ? 'flex' : 'none',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: [ReadyState.CONNECTING, ReadyState.CLOSING].includes(readyState) ? 1 : -1,
        aspectRatio: [ReadyState.CONNECTING, ReadyState.CLOSING].includes(readyState) ? 1 : 0,
      }}>
        <Spinner size="large" />
      </div>
    </div>
  )
}

function Duration({ start, end }: { start: number, end: number }): string {
  const [duration, setDuration] = useState(0)
  useEffect(() => {
    if (start && end) {
      setDuration(end - start)
    } else if (start) {
      const interval = setInterval(() => {
        setDuration((Date.now() / 1_000) - start)
      }, 1_000)
      return () => clearInterval(interval)
    }
  }, [start, end])
  if (!start) return '~s'
  return duration.toFixed(0) + 's'
}

const MEASUREMENTS_LABELS: any = {
  foot_width: "Foot Width",
  foot_length: "Foot Length",
  right_foot_width: "Right Foot Width",
  right_foot_length: "Right Foot Length",
  left_foot_width: "Left Foot Width",
  left_foot_length: "Left Foot Length",

  first_mtp_joint_length: "Toe Joint",
  right_first_mtp_joint_length: 'Right Toe Joint',
  left_first_mtp_joint_length: 'Left Toe Joint',
};

function Measure({ value }: { value: number }) {
  return <UnitValue value={value} />
}

function RunEvents() {
  let { id } = useParams();
  const events = useArray<any>([])
  useEffect(() => {
    const queryConstraints: QueryConstraint[] = [orderBy("startTimestamp", "asc")];
    // if (filter) {
    //   if (filter === 'unread')
    //     queryConstraints.unshift(
    //       where("answered", "==", false)
    //     );
    // }
    return onSnapshot(
      query(
        collection(firestore, "runs", id!, "events"),
        ...queryConstraints
      ),
      (snapshot) =>
        snapshot
          .docChanges()
          // .reverse()
          .forEach((change) => {
            const run = {
              id: change.doc.id,
              ...change.doc.data(),
            } as any;
            events.removeById(run.id);
            if (change.type !== "removed") events.push(run);
          })
    );
  }, [id]);
  return (
    <DataTable
      columnContentTypes={[
        'text',
        'numeric',
        'numeric',
      ]}
      headings={[
        'Events',
        'Start',
        'Duration',
      ]}
      rows={events.value.map(
        event => ([
          event.title, new Date(event.startTimestamp.seconds * 1_000).toLocaleTimeString(),
          <Duration start={event.startTimestamp.seconds} end={event.endTimestamp?.seconds} />,
        ])
      )}
    // footerContent={
    //   run.error && <InlineError message={run.error} fieldID="error" />
    // }
    />
  )
}

export function Run() {
  let { id } = useParams();
  const reference = doc(firestore, "runs", id!);
  const client = useQueryClient()
  const { isPending, error, data: run } = useQuery({
    queryKey: ["runs", id],
    queryFn: () => getDoc(
      reference,
    ).then(
      r => r.data()
    ),
    enabled: false,
  })
  useEffect(() => {
    return onSnapshot(
      reference,
      snapshot => {
        console.log({
          id: snapshot.id,
          ...snapshot.data(),
        })
        if (snapshot.exists())
          client.setQueryData(["runs", snapshot.id], {
            id: snapshot.id,
            ...snapshot.data(),
          })
        else
          console.error("Run #" + id + " does not exist")
      }
    )
  }, [id]);
  const { isPending: isRecordingPending, data: recording } = useQuery({
    queryKey: ["recordings", run?.recording],
    queryFn: () => getDoc(
      doc(firestore, "recordings", run?.recording!),
    ).then(
      r => r.data()
    ),
    enabled: !!run?.recording,
  })

  if (isPending)
    return <SkeletonPage>
      <Layout>
        <Layout.Section>
          <LegacyCard sectioned>
            <SkeletonBodyText />
          </LegacyCard>
          <LegacyCard sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText />
            </TextContainer>
          </LegacyCard>
          <LegacyCard sectioned>
            <TextContainer>
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText />
            </TextContainer>
          </LegacyCard>
        </Layout.Section>
        <Layout.Section variant="oneThird">
          <LegacyCard>
            <LegacyCard.Section>
              <TextContainer>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText lines={2} />
              </TextContainer>
            </LegacyCard.Section>
            <LegacyCard.Section>
              <SkeletonBodyText lines={1} />
            </LegacyCard.Section>
          </LegacyCard>
          <LegacyCard subdued>
            <LegacyCard.Section>
              <TextContainer>
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText lines={2} />
              </TextContainer>
            </LegacyCard.Section>
            <LegacyCard.Section>
              <SkeletonBodyText lines={2} />
            </LegacyCard.Section>
          </LegacyCard>
        </Layout.Section>
      </Layout>
    </SkeletonPage>

  if (!run) return <></>

  return <Page title={"Run #" + run.id} titleMetadata={STATUS_BADGES[run.status]} backAction={{
    content: "Runs",
    url: "/runs",
  }}>
    <Layout>
      <Layout.Section>
        <BlockStack gap="500">
          {/* <pre>{JSON.stringify(run, null, 2)}</pre> */}
          {run.measurements && <DataTable
            columnContentTypes={[
              'text',
              'numeric',
              'numeric',
              'numeric',
            ]}
            headings={[
              'Measurements',
              'Left',
              'Both',
              'Right',
            ]}
            rows={[
              ['Foot Length', <Measure value={run.measurements?.left_foot_length} />, <Measure value={run.measurements?.foot_length} />, <Measure value={run.measurements?.right_foot_length} />],
              ['Foot Width', <Measure value={run.measurements?.left_foot_width} />, <Measure value={run.measurements?.foot_width} />, <Measure value={run.measurements?.right_foot_width} />],
              ['Toe Joint', <Measure value={run.measurements?.left_first_mtp_joint_length} />, <Measure value={run.measurements?.first_mtp_joint_length} />, <Measure value={run.measurements?.right_first_mtp_joint_length} />],
            ]}
          />}
          <RunEvents />
        </BlockStack>
        <pre>{JSON.stringify(run, null, 2)}</pre>
      </Layout.Section>
      <Layout.Section variant="oneThird">
        {run.startTimestamp && <Card padding="0">
          {
            run.status >= 2 && run.pageId && (Date.now() / 1000 - run.startTimestamp.seconds) < 90 && <Cast pageId={run.pageId} loadStatus={() => { }} />
          }
          {/* {recording && (Date.now() / 1000 - run.startTimestamp.seconds) > 90 && <Video source={recording!.recording} />} */}
        </Card>}
      </Layout.Section>
    </Layout>
  </Page>
}